<template>
  <div>
    <swiper
      :slidesPerView="perView"
      :slidesPerColumn="2"
      :spaceBetween="20"
      :speed="2000"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
      :autoplay="{
        delay: 4000,
        disableOnInteraction: false,
      }"
      :breakpoints="{
        '768': {
          spaceBetween: 45,
        },
        '1024': {
          spaceBetween: 65,
        },
      }"
    >
      <swiper-slide v-for="(item, index) in data" :key="item.id">
        <img
          :src="item.url"
          rel="preload"
          width="150"
          height="85"
          :alt="item.id"
          @mouseover="
            memImageUrl = item.url;
            item.url = active[index].url;
          "
          @mouseleave="item.url = memImageUrl"
        />
      </swiper-slide>
    </swiper>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.min.css";

// import Swiper core and required modules
import SwiperCore, { Autoplay, Navigation } from "swiper/core";

// install Swiper modules
SwiperCore.use([Autoplay, Navigation]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    perView: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    active: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      memImageUrl: null,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.swiper-container {
  position: relative;
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  overflow: initial;
  @include property("margin-top", 40, 20, true, 768, 320);
  overflow: hidden;
}

.swiper-slide {
  @include property("height", 85, 65, true, 1670, 1024);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;

    @media (hover) {
      filter: grayscale(1);
      transition: all 0.3s linear;

      &:hover {
        filter: grayscale(0);
      }
    }
  }
}
</style>
